
.row-field{
  display: flex;
  flex-direction: row;
  align-content: center;
  align-items: center;
}
.row-field > div,
.row-field > textarea
.row-field > input{
  width: 50%;
}
.row-field > label{
  width: 50%;
  font-weight: bold;
}
.selector-dropdown{
  width: 70%;
}
.ui.form input[type="number"],
.ui.input input[type="number"]{
  text-align: right;
  -moz-appearance: textfield !important;
}
.d-flex{
  display: flex;
}
.tres-cuartos{
  height: 70vh;
  flex-grow: 1;
}
.wizard .tres-cuartos{
  height: 55vh;
}
.wizard .equation
{
  height: 45vh;
}
.m-item{
  padding: 7px 10px 7px 20px;
  color: #000;
  border-radius: 2px;
  cursor: pointer;
}
.m-item:hover{
  background-color: #f1f4f9;
  color: #4a8afd !important;
}
.m-description{
  width: 90%;
  word-wrap: break-word;
}
.m-title{
  line-height: 20px;
  font-size: 15px;
  color: #101010;
}
.m-subdescription{
  color: gray;
  font-size: 12px;
}
.m-stock{
  width: 30%;
  font-size: 13px;
  text-align: right;
}
.stock-available{
  color: #029745;
}
.w-half{
width: 50% !important;
display: flex;
flex-direction: column;
}
.f-row{
  flex-direction: row;
}
.m-item.active .selected{
  display: flex;
}
.m-item:not(.active):hover .to-select{
  display: flex;
}
.m-item .selected,
.m-item .to-select{
  display: none;
}
.tick{
  display: flex;
  align-items: center;
  justify-content: center;
  width: 10%;
}
.item-name{
  width: 70%;
  display: flex;
}
.quantity-section{
  width: 20%;
}
.row-field .element-fluid{
  width: 100%;
}
.direccion-data{
  color: #777 !important;
  font-size: 12px;
  text-align: left; 
}
.t-right{
  text-align: right;
  margin-bottom: 1rem;
  padding-right: 0.75rem
}
.mr-5{
  margin-right: 5px !important;
}
.mt-5{
  margin-top: 5px !important;
}
.p-1{
  padding: 1rem;
}
.out-stock{
  color: red
}
.quantity-section .ui.loader::after{
  border-color: #ccc transparent transparent !important;
}
.stock-hand{
  color: orange;
}
.stock-commited{
  color: red;
}
.stock-available{
color: green;
}
.item-details{
  line-height: 1;
  font-size: 12px;
}
.w-150{
  min-width: 150px;
}
.w-100{
  min-width: 100px;
}
.w-50{
  min-width: 50px;
}
.n-p{
  padding: 5px !important;
}
.full-width .modal-lg{
  max-width: 90%;
}
.modal-lg .panel-container.panel-small{
  height: 300px;
}
input[type="number"]{
  text-align: right;
  -moz-appearance: textfield !important;
}
.equation{
  display: flex;
  padding: 2rem 1rem;
  align-items: center;
  align-content: center;
  font-size: 1.35em;
  font-weight: bold;
}
.equation-left,
.equation-right{
  flex-grow: 1;
}
.cursor-pointer{
  cursor: pointer;
}
.panel-container{
  flex-grow: 1;
}
.user.simple-icon-user{
  font-size: 1.5rem;
  vertical-align: middle;
  margin-left: 10px;
}
.name{
  font-size: 1rem;
  vertical-align: middle;
}
.table td.text-field{
  padding: 1.5rem  0.75rem;
}
.price-field{
  width: auto;
  max-width: 100px;
}
.content-center{
  display: flex;
  justify-content: center;
}
.description{
  white-space: pre-wrap;
}
.search.report{
  position: relative;
  width: 230px;
  border-radius: 20px;
  background: #f8f8f8;
}